<template>
  <div class="publications">
    <page-header :title="title" icon="fa fa-envelope-circle-check" :links="links"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row>
      <b-col>
        <tabs-bar
          enabled
          :tabs="tabs"
          :active="activeTab"
          @change="onTabChanged($event)"
        >
        </tabs-bar>
      </b-col>
    </b-row>
    <div ref="printMe">
      <div v-if="activeTab && activeTab.selector === 'detail'" class="tab-content">
        <b-row>
          <b-col cols="2">
            <b-form-group label-for="publicationType" label="Type">
              <b-select
                v-model="publicationType"
                :disabled="!editMode"
              >
                <b-select-option v-for="elt of publicationTypes" :key="elt.id" :value="elt">
                  {{ elt.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="fromNumber" label="Du N°">
              <b-input
                id="fromNumber"
                v-model="fromNumber"
                type="number" step="1" min="0"
                :disabled="!editMode"
              >
              </b-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="toNumber" label="au N°">
              <b-input
                id="toNumber"
                v-model="toNumber"
                type="number" step="1" min="0"
                :disabled="!editMode"
              >
              </b-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="publishedOn" label="Date de publication">
              <b-input id="publishedOn" v-model="publishedOn" type="date" :disabled="!editMode">
              </b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-form-group label-for="price" label="Prix">
              <decimal-input v-model="price" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="sendingPrice" label="Prix d'envoi">
              <decimal-input v-model="sendingPrice" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="fabPrice" label="Prix de revient">
              <decimal-input v-model="fabPrice" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="authorPrice" label="Prix auteur">
              <decimal-input v-model="authorPrice" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="isAvailable" label="Disponible">
              <b-form-checkbox v-model="isAvailable" :disabled="!editMode"></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="notes" label="Notes">
              <b-textarea id="notes" v-model="notes" :disabled="!editMode">
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="canEdit">
          <b-col class="text-right">
            <a href @click.prevent="onEdit()" class="btn btn-secondary" v-if="!editMode">
              <i class="fa fa-edit"></i> Modifier
            </a>
            <a href @click.prevent="onCancel()" class="btn btn-secondary" v-if="editMode">
              <i class="fa fa-close"></i> Annuler
            </a>
            &nbsp;
            <a href @click.prevent="onSave()" class="btn btn-primary" v-if="editMode">
              <i class="fa fa-save"></i> Enregistrer
            </a>
          </b-col>
        </b-row>
      </div>
      <div v-if="activeTab && activeTab.selector === 'subscriptors'" class="tab-content">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <b>{{ subscription.fullName() }}</b>
          <div v-if="subscriptors.length === 0" class="empty-text">
            Aucun exemplaire
          </div>
          <div v-else>
            <x-table
              id="subscriptors"
              class="small"
              :columns="subscriptorColumns"
              :items="subscriptorItems"
              :show-footer="true"
              :show-counter="true"
              verbose-name="exemplaire"
            ></x-table>
          </div>
        </div>
      </div>
    </div>
    <add-subscriptor-modal
      :subscription="subscription"
      v-if="subscription"
      modal-id="bv-edit-subscriptor"
      @done="loadSubscriptors()"
    ></add-subscriptor-modal>
    <confirm-modal
      name="delete-subscriptor"
      icon="fa fa-trash"
      :object="selectedSubscriptor"
      title="Suppression d'un abonné"
      :text="deleteCopyText"
      @confirmed="onConfirmDelete"
    ></confirm-modal>
    <payment-modal
      modal-id="pay-subscriptor-modal"
      :entity="paymentEntity"
      :invoice="paymentInvoice"
      @paid="loadSubscriptors()"
    >
    </payment-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import store from '@/store'
import { mapActions, mapMutations } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import TabsBar from '@/components/Controls/TabsBar.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import AddSubscriptorModal from '@/components/Publications/AddSubscriptorModal.vue'
import PaymentModal from '@/components/Payments/PaymentModal.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { makeSubscriptor, makePublicationType, makeSubscription } from '@/types/publications'
import { slugify } from '@/utils/strings'
import { TabItem } from '@/types/tabs'

export default {
  name: 'subscriptions-edit',
  mixins: [BackendMixin],
  components: {
    PaymentModal,
    ConfirmModal,
    AddSubscriptorModal,
    XTable,
    TabsBar,
    DecimalInput,
    LoadingGif,
    PageHeader,
  },
  props: {
    subscriptionId: [String, Number],
  },
  data() {
    return {
      loadingName: 'subscriptions-edit',
      publicationType: null,
      publicationTypes: [],
      publishedOn: '',
      fromNumber: 0,
      toNumber: 0,
      subscription: null,
      price: null,
      sendingPrice: null,
      fabPrice: null,
      authorPrice: null,
      isAvailable: false,
      notes: '',
      editMode: false,
      activeTab: null,
      subscriptors: [],
      subscriptorColumns: [],
      selectedSubscriptor: null,
      paymentEntity: null,
      paymentInvoice: null,
    }
  },
  watch: {
    subscriptionId: function() {
      this.loadSubscription()
    },
    publicationType: function() {
      if (this.publicationType && !this.publicationId) {
        this.publicationNumber = this.publicationType.next
        this.price = this.publicationType.price
        this.sendingPrice = this.publicationType.sendingPrice
      }
    },
  },
  computed: {
    title() {
      return 'Abonnement ' + store.getters.config.publicationTitle
    },
    tabs() {
      const tabs = [
        new TabItem('detail', 'Fiche', 'fa fa-th')
      ]
      if (this.subscriptionId) {
        tabs.push(
          new TabItem('subscriptors', 'Abonnés', 'fa fa-envelopes-bulk')
        )
      }
      return tabs
    },
    subscriptorItems() {
      return this.subscriptors.map(this.makeSubscriptorItem)
    },
    links() {
      const detail = this.activeTab && this.activeTab.selector === 'detail'
      const canExcel = !this.isLoading(this.loadingName) && (!detail)
      const canAdd = this.activeTab && this.activeTab.selector === 'subscriptors'
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.loadingName)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (!canExcel) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (canAdd && this.hasPerm('publications.add_subscriptor')) {
        links.push(
          {
            id: 3,
            label: 'Nouvel abonné',
            callback: () => {
              this.$bvModal.show('bv-edit-subscriptor')
            },
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      return links
    },
    deleteCopyText() {
      let text = 'Êtes-vous sûr de supprimer l\'abonné'
      if (this.selectedSubscriptor) {
        text += ' ' + this.selectedSubscriptor.entity.name
      }
      return text
    },
    canEdit() {
      return this.hasPerm('publications.change_publication')
    },
  },
  mounted() {
    this.activeTab = this.tabs[0]
    this.subscriptorColumns = this.getSubscriptorColumns()
    this.loadSubscription()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getSubscriptorColumns() {
      const cols = [
        {
          name: 'entityName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entity.id)
          },
        },
        {
          name: 'address',
          label: 'Adresse',
        },
        {
          name: 'zipCode',
          label: 'CP',
        },
        {
          name: 'city',
          label: 'Ville',
        },
        {
          name: 'createdOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'sending',
          label: 'envoi',
        },
        {
          name: 'sentOn',
          label: 'envoyé le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'quantity',
          label: 'Quantité',
          number: true,
          colFooterSum: true,
        },
        {
          name: 'unitPrice',
          label: 'Prix unitaire',
          currency: true,
        },
        {
          name: 'discount',
          label: 'Réduction',
          currency: true,
        },
        {
          name: 'sendingPrice',
          label: 'Prix envoi',
          currency: true,
        },
        {
          name: 'totalPrice',
          label: 'Prix total',
          currency: true,
          colFooterSum: true,
        },
        {
          name: 'invoicing',
          label: '',
          hideFilter: false,
          isLink: item => {
            return (item.invoice)
          },
          linkUrl: item => {
            if (item.invoice) {
              return router.resolve(
                { name: 'invoice-detail', params: { invoiceId: '' + item.invoice.id, }, }
              ).href
            }
          },
          contentCallback: (col, item) => {
            if (item.invoice) {
              return '<span class="badge ' + item.invoice.getStyle() + '">' + item.invoicing + '</span>'
            } else {
              return '<span class="badge todo-invoice">' + item.invoicing + '</span>'
            }
          },
        },
        {
          name: 'pay',
          label: '',
          hideFilter: true,
          onClick: item => {
            if (item.pay) {
              this.payInvoice(item)
            }
          },
          isLink: item => {
            return item.pay
          },
          contentCallback: (col, item) => {
            if (item.pay) {
              return '<span class="btn btn-secondary btn-sm">' +
                '<i class="fa fa-money-bill")></i>' +
                '</span>'
            }
            return ''
          },
        }
      ]
      if (this.hasPerm('publications.delete_subscriptor')) {
        cols.push(
          {
            name: 'delete',
            label: '  ',
            hideFilter: true,
            alignRight: true,
            maxWidth: '25px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onDeleteCopy(item)
            },
          }
        )
      }
      return cols
    },
    makeSubscriptorItem(subscriptor) {
      let invoice = null
      let pay = 0
      if (subscriptor.sale) {
        invoice = subscriptor.sale.invoice
        if (invoice) {
          pay = invoice.toBePaidPrice()
        }
      }
      return {
        id: subscriptor.id,
        entityName: subscriptor.entity.name,
        entity: subscriptor.entity,
        address: subscriptor.entity.addressText(),
        zipCode: subscriptor.entity.zipCode,
        city: subscriptor.entity.city.name,
        createdOn: subscriptor.createdOn,
        sentOn: subscriptor.sentOn | null,
        totalPrice: subscriptor.totalPrice,
        unitPrice: subscriptor.unitPrice,
        discount: subscriptor.discount,
        sendingPrice: subscriptor.discount,
        sending: subscriptor.sending ? 'Oui' : '',
        type: subscriptor.deposit ? 'Dépôt' : '',
        quantity: subscriptor.quantity,
        delete: '<i class="fas fa-trash"></i>',
        subscriptor: subscriptor,
        invoice: invoice,
        invoicing: invoice ? invoice.status : 'à facturer',
        pay: pay,
      }
    },
    async init() {
      if (this.subscription) {
        this.publicationType = this.subscription.publicationType
        this.publicationTypes = [this.publicationType]
        if (this.subscription.publishedOn) {
          this.publishedOn = dateToString(this.subscription.publishedOn, 'YYYY-MM-DD')
        } else {
          this.publishedOn = ''
        }
        this.fromNumber = this.subscription.fromNumber
        this.toNumber = this.subscription.toNumber
        this.price = this.subscription.price
        this.sendingPrice = this.subscription.sendingPrice
        this.fabPrice = this.subscription.fabPrice
        this.authorPrice = this.subscription.authorPrice
        this.isAvailable = this.subscription.isAvailable
        this.notes = this.subscription.notes
        this.editMode = false
      } else {
        this.publishedOn = ''
        this.fromNumber = 0
        this.toNumber = 0
        if (this.publicationTypes.length === 1) {
          this.publicationType = this.publicationTypes[0]
        }
        this.price = null
        this.sendingPrice = null
        this.fabPrice = null
        this.authorPrice = null
        this.isAvailable = true
        this.notes = ''
        this.editMode = true
      }
    },
    async loadPublicationTypes() {
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publication-types/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.publicationTypes = resp.data.map(makePublicationType).filter(elt => elt.isPublication)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadSubscription() {
      if (this.subscriptionId) {
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/subscriptions/' + this.subscriptionId + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.subscription = makeSubscription(resp.data)
          await this.init()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        await this.loadPublicationTypes()
        await this.init()
      }
    },
    onEdit() {
      this.editMode = true
    },
    onCancel() {
      if (this.subscription) {
        this.editMode = false
      } else {
        this.$router.push({ name: 'publications-home', })
      }
    },
    async onSave() {
      this.startLoading(this.loadingName)
      let url = '/publications/api/subscriptions/'
      let method = 'post'
      if (this.subscription) {
        url += this.subscriptionId + '/'
        method = 'patch'
      }
      const data = {
        'publication_type': this.publicationType.id,
        'from_number': this.fromNumber,
        'to_number': this.toNumber,
        'published_on': this.publishedOn ? dateToString(this.publishedOn, 'YYYY-MM-DD') : null,
        'price': this.price,
        'sending_price': this.sendingPrice,
        'author_price': this.authorPrice,
        'is_available': this.isAvailable,
        'fab_price': this.fabPrice,
        'notes': this.notes,
      }
      const backendApi = new BackendApi(method, url)
      try {
        const resp = await backendApi.callApi(data)
        const subscription = makeSubscription(resp.data)
        if (this.subscriptionId) {
          this.subscription = subscription
          await this.init()
        } else {
          await router.push(
            { name: 'subscriptions-edit', params: { 'subscriptionId': subscription.id, }, }
          )
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadSubscriptors() {
      if (this.subscriptionId) {
        this.subscriptors = []
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/subscriptors/' + this.subscriptionId + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.subscriptors = resp.data.map(makeSubscriptor)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onTabChanged(tab) {
      this.activeTab = tab
      if (tab.selector === 'subscriptors') {
        this.loadSubscriptors()
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.activeTab.label + '-' + this.subscription.fullName())
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify(this.activeTab.label + '-' + this.subscription.fullName())
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDeleteCopy(item) {
      this.selectedSubscriptor = item.subscriptor
      this.$bvModal.show('bv-confirm-modal:delete-subscriptor')
    },
    async onConfirmDelete(event) {
      const subscriptor = event.object
      try {
        const url = '/publications/api/delete-subscriptor/' + subscriptor.id + '/'
        const backendApi = new BackendApi('delete', url)
        await backendApi.callApi()
        await this.addSuccess('L\'abonné a été supprimé')
        await this.loadSubscriptors()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    payInvoice(item) {
      this.paymentEntity = item.entity
      this.paymentInvoice = item.invoice
      this.$nextTick(
        () => {
          this.$bvModal.show('pay-subscriptor-modal')
        }
      )
    },
  },
}
</script>

<style scoped lang="less">
.tab-content {
  margin-top: 5px;
}
</style>
