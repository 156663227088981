<template>
  <span class="edit-subscriptor" v-if="hasPerm('publications.add_subscriptor')">
    <b-modal dialog-class="modal-lg"
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-book-reader"></i> Nouvel abonné<br />{{ subscription.fullName() }}</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="createdOn-group"
            description="Date"
            label-for="createdOn"
          >
            <b-form-input type="date" id="createdOn" v-model="createdOn">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="quantity-group"
            description="Quantité"
            label-for="quantity"
          >
            <b-form-input type="number" step=1 min=1 id="quantity" v-model="quantity"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="unitPrice"
            description="Prix unitaire"
            label-for="unitPrice"
            required
          >
            <decimal-input id="unitPrice" v-model="unitPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-checkbox v-model="sending" id="sending">
            Envoi
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sendingPrice"
            description="Tarif envoi"
            label-for="sendingPrice"
          >
            <decimal-input :disabled="!sending" id="sendingPrice" v-model="sendingPrice"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="discount"
            description="Réduction"
            label-for="discount"
            required
          >
            <decimal-input id="discount" v-model="discount"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="totalPrice"
            description="Prix total"
            label-for="totalPrice"
            required
          >
            <decimal-input disabled id="totalPrice" v-model="totalPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <entity-typeahead
            @change="onEntityChanged"
            @init="onEntityChanged"
          >
          </entity-typeahead>
        </b-col>
      </b-row>
      <b-row v-if="totalPrice">
        <b-col>
          <b-checkbox v-model="autoInvoice" id="autoInvoice">
            Facturer automatiquement la vente
          </b-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import EntityTypeahead from '@/components/Entity/EntityTypeahead.vue'
import { makeSubscriptor } from '@/types/publications'
import { BackendApi } from '@/utils/http'

export default {
  name: 'edit-subscriptor-modal',
  components: { EntityTypeahead, DecimalInput, },
  mixins: [BackendMixin],
  props: {
    subscription: Object,
    modalId: {
      type: String,
      default: 'bv-edit-subscriptor',
    },
  },
  data() {
    return {
      createdOn: null,
      totalPrice: 0,
      unitPrice: 0,
      discount: 0,
      sending: 0,
      sendingPrice: 0,
      errorText: '',
      quantity: 1,
      entity: null,
      autoInvoice: false,
    }
  },
  computed: {
  },
  watch: {
    unitPrice: function() { this.updatePrice() },
    quantity: function() { this.updatePrice() },
    sendingPrice: function() { this.updatePrice() },
    sending: function() { this.updatePrice() },
    discount: function() { this.updatePrice() },
    subscription: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.sendingPrice = this.subscription.sendingPrice
      this.unitPrice = this.subscription.price
    },
    isDisabled() {
      return (
        (this.createdOn === null) ||
        (this.quantity <= 0) ||
        !this.entity
      )
    },
    async onSave() {
      this.errorText = ''
      try {
        const url = '/publications/api/add-subscriptor/'
        const backendApi = new BackendApi('post', url)
        let data = {
          subscription: this.subscription.id,
          entity: this.entity.id,
          unit_price: this.unitPrice,
          sending: this.sending,
          sending_price: this.sending ? this.sendingPrice : 0,
          discount: this.discount,
          total_price: this.totalPrice,
          created_on: moment(this.createdOn).format('YYYY-MM-DD'),
          auto_invoice: this.autoInvoice,
        }
        const resp = await backendApi.callApi(data)
        this.$bvModal.hide(this.modalId)
        this.$emit('done', makeSubscriptor(resp.data))
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    updatePrice() {
      let sendingPrice = 0
      if (this.sending) {
        sendingPrice = this.sendingPrice
      }
      this.totalPrice = this.quantity * this.unitPrice + sendingPrice - this.discount
    },
    onEntityChanged(event) {
      if (event.entity) {
        this.entity = event.entity
      } else {
        this.entity = null
      }
    },
  },
  mounted() {
    this.createdOn = moment().format('YYYY-MM-DD')
    this.init()
  },
}
</script>
<style lang="less">
</style>
